import React, { useContext } from "react";
import { AccountNav, AuthContext } from "components/lib";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router";
import { getPlans } from "constants/shared";

export function AccountPlan() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const plans = getPlans();

  const userPlan = plans.find((plan) => plan.id === authContext?.user?.plan);

  const handleUpgrade = () => {
    navigate("/upgrade");
  };

  return (
    <>
      <AccountNav />

      {/* Account Plan Card */}
      <Card sx={{ maxWidth: 600, mt: 2, textAlign: "center" }}>
        <CardHeader
          title="Your Account Plan"
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {userPlan?.title || "No Plan Available"}
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            {userPlan?.description || "No description available."}
          </Typography>
          {authContext?.user?.expires_at && (
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Expires on:{" "}
              {new Date(authContext.user.expires_at).toLocaleDateString()}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpgrade}
            sx={{ mt: 2 }}
          >
            Upgrade Plan
          </Button>
        </CardContent>
      </Card>

      {/* Features Card */}
      {userPlan?.features && (
        <Card sx={{ maxWidth: 600, mt: 2, textAlign: "left" }}>
          <CardHeader
            title="Plan Features"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            <List>
              {userPlan.features.map((feature, index) => (
                <ListItem key={index}>
                  <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </>
  );
}
