import React, { Fragment, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Paper,
} from "@mui/material";
import {
  AuthContext,
  AccountNav,
  Animate,
  Message,
  useAPI,
} from "components/lib";

export function AccountPayments() {
  const authContext = useContext(AuthContext);
  const { loading, data: payments, error } = useAPI("/api/payments");

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
          Payment History
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Message title="Error" type="error" text={error} />
        ) : payments?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Payment Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.id}</TableCell>
                    <TableCell>${payment.amount}</TableCell>
                    <TableCell>{payment.plan}</TableCell>
                    <TableCell>
                      {new Date(payment.created_at).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No payments found.</Typography>
        )}
      </Animate>
    </Fragment>
  );
}
