import React from "react";
import { Typography, Container, Box, List, ListItem } from "@mui/material";

export function Terms() {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ py: 4 }}>
        Terms & Conditions
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        The following terms and conditions (this "TOS") govern the use of the
        prolinksqr.com website and all domains, webpages, subdomains, and
        subparts therein contained (the "WEBSITE"). All services available
        through the WEBSITE are provided by ProlinksQR.
        (“ProlinksQR”).
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        The WEBSITE and all services (collectively, the "SERVICES") are offered
        subject to your acceptance without modification of all of the terms and
        conditions contained herein and all other operating rules, policies, and
        procedures that may be published from time to time on the WEBSITE by
        ProlinksQR (collectively, "THE AGREEMENT").
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        By using or accessing any part of the WEBSITE, you agree to become bound
        by all of the TOS of this agreement. If you do not agree to all of the
        TOS contained in this agreement, you may not access the WEBSITE or use
        any of its SERVICES.
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        ProlinksQR provides Dynamic QR Code Solutions. Payments are
        transacted through Stripe ("Facilitated Payment Mode", or "FPM").
      </Typography>

      <Typography variant="h5" color="primary" gutterBottom>
        1. Modification of These Terms
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        ProlinksQR reserves the right, at its sole discretion, to modify or
        replace any part of this TOS at any time. It is your responsibility to
        check this TOS periodically for changes. Your continued use of or access
        to the WEBSITE following the posting of any changes to this TOS
        constitutes acceptance of those changes. If any change to this TOS is
        not acceptable to you, you must cease accessing the WEBSITE and its
        SERVICES.
      </Typography>

      <Typography variant="h5" color="primary" gutterBottom>
        2. Conduct
      </Typography>

      <Typography variant="h6" gutterBottom>
        2.1 Your use of the SERVICES
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        ProlinksQR hereby grants you a non-exclusive, non-transferable,
        non-sublicensable right to access and use the SERVICES solely for the
        purposes of [DESCRIPTION_OF_PURPOSE], in each case in compliance with
        the TOS.
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        You must not:
      </Typography>

      <List sx={{ pl: 2 }}>
        <ListItem>
          Reverse engineer, disassemble, decompile, or otherwise attempt to
          discover the source code or structure of all or any part of the
          SERVICES. Remove or alter any proprietary notices or labels on or in
          the SERVICES or WEBSITE content.
        </ListItem>
        <ListItem>
          Engage in any activity that interferes with or disrupts the SERVICES,
          or engage in any fraudulent activity or activity that facilitates
          fraud.
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        2.2 Your Registration Obligations
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        By registering an account on the WEBSITE, you agree to:
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        Provide true, accurate, current, and complete information about yourself
        as prompted by the WEBSITE registration form (the "REGISTRATION DATA")
        and maintain and promptly update the REGISTRATION DATA to keep it true,
        accurate, current, and complete.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2.3 Your Account Security
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        By registering an account on the WEBSITE, you are fully responsible for
        maintaining the security and confidentiality of your account. You are
        fully responsible for all activities that occur under your account,
        including, without limitation, all actions by sub-users with access to
        your account. You agree to immediately notify ProlinksQR of any
        unauthorized access to your account. ProlinksQR will not be liable
        for any loss, damage, or other liability arising from your failure to
        comply with this section of the TOS or from any unauthorized access to
        or use of your account.
      </Typography>

      <Typography variant="h5" color="primary" gutterBottom>
        3. Content
      </Typography>

      <Typography variant="h6" gutterBottom>
        3.1 Your Content
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        If you post any links, videos, audio files, photographs, graphics, or
        computer software ("CONTENT") to the WEBSITE, you hereby grant
        ProlinksQR a non-exclusive, worldwide, royalty-free, transferable,
        sublicensable right to use, reproduce, adapt, modify, distribute,
        translate, and publish your CONTENT, in whole or in part, for any
        purpose whatsoever.
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        You are entirely responsible for your CONTENT and any harm resulting
        from your CONTENT. By making your CONTENT available through the
        SERVICES, you agree not to:
      </Typography>

      <List sx={{ pl: 2 }}>
        {[
          "Upload, post, email, transmit or otherwise make available any CONTENT that is unlawful, harmful, threatening, abusive, harassing, pornographic, tortuous, defamatory, vulgar, obscene, libelous, racist or invasive of another person's privacy.",
          "Make available any CONTENT that infringes any patent, trademark, copyright, trade secret, or any other intellectual property or proprietary rights of any individual or organization.",
          "Upload, post, email, transmit or install any malicious software, such as worms, malware, spyware, Trojan horses, or other destructive or harmful content.",
          "Interfere with or disrupt the SERVICES, or networks connected to the SERVICES.",
          "Use an account name that misleads customers or potential customers into believing that you are another person or company.",
        ].map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>

      <Typography variant="h5" color="primary" gutterBottom>
        10. Limitation of Liability
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        ProlinksQR and its suppliers or licensors shall not be liable under
        any circumstances, whether by contract, negligence, strict liability, or
        other legal theory for:
      </Typography>

      <List sx={{ pl: 2 }}>
        {[
          "Any special, indirect, incidental, or consequential damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.",
          "The cost of procurement for substitute products or services due to interruption, discontinuance of use, loss of, or corruption of data.",
          "Any matters beyond ProlinksQR reasonable control.",
          "Your content or any content of any other users of the SERVICES.",
        ].map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>

      <Typography variant="h5" color="primary" gutterBottom>
        11. Indemnity
      </Typography>

      <Typography display="block" sx={{ mb: 2 }}>
        You agree to defend, indemnify, and hold ProlinksQR and its
        affiliates, suppliers, contractors, licensors, and their respective
        directors, officers, employees, and agents from and against all claims
        and expenses, including attorneys' fees and accounting fees, arising out
        of your use of the WEBSITE, including your violation of this AGREEMENT.
      </Typography>

      <Typography display="block" sx={{ mt: 4 }}>
        ProlinksQR reserves the right to modify these terms at any time.
        Changes will be made available on this page.
      </Typography>
    </Container>
  );
}
