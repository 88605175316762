import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardContent,
  Button,
  Card,
} from "@mui/material";
import { CheckCircle, Star } from "@mui/icons-material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid2";
import { AuthContext } from "components/lib";
import { useNavigate } from "react-router";
import { getPlans } from "constants/shared";

const PRODUCT_CODES = {
  Enterprise: "ZSE3EZ610F",
  Professional: "BMVQF12OVT",
  Standard: "RNM05V6Y8Z",
  Starter: "S7UGUIAVQF",
};

const StyledCard = styled(Card)(({ theme, isPopular }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  position: "relative",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: theme.shadows[10],
  },
  ...(isPopular && {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[10],
  }),
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.25rem",
  marginBottom: theme.spacing(2),
}));

const PlanPrice = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "2rem",
  marginBottom: theme.spacing(2),
}));

const PlanDescription = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  marginBottom: theme.spacing(2),
}));

const MostPopularChip = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 16,
  left: 0,
  width: "100%",
  textAlign: "center",
  padding: theme.spacing(1),
  color: "orange",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  backgroundColor: "transparent",
}));

const BillingTabs = ({ billingPeriod, onBillingPeriodChange }) => (
  <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
    <Tabs
      value={billingPeriod}
      onChange={onBillingPeriodChange}
      aria-label="billing period tabs"
      centered
    >
      <Tab label="Monthly" value="monthly" />
      <Tab label="Yearly" value="yearly" />
    </Tabs>
  </Box>
);

const PlanCard = ({ plan, billingPeriod, accountId }) => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const account_signature = context?.user?.account_signature;

  const handleCheckout = () => {
    if (!accountId) {
      alert("Account ID is required to proceed.");
      return;
    }

    const productCode = PRODUCT_CODES[plan.title];
    if (productCode) {
      const url = `https://secure.2checkout.com/checkout/buy?merchant=255355397355&tpl=default&review=1&prod=${productCode}&qty=1&customer-ext-ref=${accountId}&signature=${account_signature}`;
      window.open(url, "_blank");
    }
  };

  return (
    <StyledCard isPopular={plan.isPopular}>
      {plan.isPopular && (
        <MostPopularChip>
          <Star sx={{ marginRight: "4px" }} />
          Most Popular
        </MostPopularChip>
      )}
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "48px",
        }}
      >
        <PlanTitle variant="h5">{plan.title}</PlanTitle>
        <PlanPrice>
          {billingPeriod === "monthly" ? plan.monthlyPrice : plan.yearlyPrice}
          <Typography component="span" variant="subtitle1">
            {billingPeriod === "monthly" ? "/month" : "/year"}
          </Typography>
        </PlanPrice>
        <PlanDescription variant="body1" color="text.secondary">
          {plan.description}
        </PlanDescription>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mb: 1 }}
          //onClick={() => navigate("/signup")}
          onClick={handleCheckout}
        >
          Try for 7 days
        </Button>
        <Typography variant="caption" color="text.secondary" textAlign="center">
          * No credit card needed
        </Typography>
        <List sx={{ mt: 2, flexGrow: 1 }}>
          {plan.features.map((feature, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon sx={{ minWidth: 24 }}>
                <CheckCircle color="success" sx={{ marginRight: "4px" }} />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </StyledCard>
  );
};

export function PricePlans() {
  const [billingPeriod, setBillingPeriod] = useState("yearly");
  const context = useContext(AuthContext); // Access account ID from context

  const handleBillingPeriodChange = (event, newValue) => {
    setBillingPeriod(newValue);
  };

  const plans = getPlans();

  return (
    <Box sx={{ backgroundColor: "#f6f9ff" }}>
      <Container maxWidth="xl" sx={{ py: 8 }}>
        <Box textAlign="center" mb={6}>
          <Typography variant="h4" gutterBottom fontWeight="bold">
            Choose Your Perfect Plan
          </Typography>
          <Typography variant="h5" color="text.secondary">
            Flexible pricing options to suit your business needs
          </Typography>
        </Box>
        <BillingTabs
          billingPeriod={billingPeriod}
          onBillingPeriodChange={handleBillingPeriodChange}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {plans.map((plan) => (
            <Grid key={plan.title} size={{ md: 3, sm: 6, xs: 12 }}>
              <PlanCard
                plan={plan}
                billingPeriod={billingPeriod}
                accountId={context?.user?.account_id}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
