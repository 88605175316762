import React, { useContext, useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import axios from "axios";
import { AuthContext, Logo } from "components/lib";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export function Signin() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [serverError, setServerError] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setServerError(""); // Clear previous server error
    try {
      const response = await axios.post("/api/auth", data);
      if (data.useMagic) {
        setMagicLinkSent(true);
      } else if (response.data["2fa_required"]) {
        navigate(`/signin/otp?token=${response.data.token}`);
      } else {
        navigate(authContext.signin(response));
      }
    } catch (error) {
      console.log("HEREE");
      console.log(error.message);
      setServerError(
        error.response?.data?.message || "An unexpected error occurred"
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#1a2b3c", // Dark blue background
      }}
    >
      <Card sx={{ padding: 6, borderRadius: 2 }}>
        <CardContent>
          <Box width="100%" mb={4}>
            <Box width={160} height={30}>
              <Logo color="yes" />
            </Box>
          </Box>
          <Typography
            variant="h5"
            align="center"
            fontWeight={"bold"}
            gutterBottom
          >
            Login
          </Typography>

          {magicLinkSent ? (
            <Typography align="center" color="primary">
              Please check your email for the magic link to sign in.
            </Typography>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                  />
                )}
              />

              {serverError && (
                <Typography color="error" variant="body2" sx={{ mt: 1, mb: 1 }}>
                  {serverError}
                </Typography>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <div></div>
                {/* <FormControlLabel control={<Checkbox />} label="Remember me" /> */}
                <Link
                  onClick={() => navigate("/forgotpassword")}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  Forgot password?
                </Link>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3 }}
                disabled={isSubmitting || !!Object.keys(errors).length}
              >
                Log in
              </Button>

              <Divider sx={{ my: 2 }}>or</Divider>

              <Typography align="left">
                Not on Prolinks yet?{" "}
                <Link
                  onClick={() => navigate("/signup")}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                >
                  Sign up
                </Link>
              </Typography>
            </form>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
