/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment, useEffect } from "react";
import { Row, Button, Hero, Features, Testimonial } from "components/lib";
import ImgDashboard from "./images/dashboard.png";
import ImgKyleGawley from "./images/kyle-gawley.jpg";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { loadTidioScript } from "app/livechat";
import { Faq } from "./faq";
import { MiniPricing } from "./minipricing";

import Hotjar from "@hotjar/browser";

const siteId = 5300981;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

export function Home(props) {
  useEffect(() => {
    loadTidioScript();
  }, []);

  return (
    <Fragment>
      <Hero
        title="Create Dynamic QR Codes with Ease"
        tagline="Prolinks is the ultimate solution for generating and managing dynamic QR codes. Track scans, update links, and customize your codes — all in one platform."
        image={ImgDashboard}
      />

      <Container maxWidth="xl" sx={{ py: 6 }}>
        <Box>
          <Features />
        </Box>
      </Container>

      <Box
        sx={{
          backgroundColor: "#1A1A1A",
        }}
      >
        <Container maxWidth="xl" sx={{ py: 6 }}>
          <MiniPricing />
        </Container>
      </Box>

      <Box sx={{ backgroundColor: "#f7f7f7" }}>
        <Container maxWidth="xl" sx={{ py: 6 }}>
          <Faq />
        </Container>
      </Box>

      {/* <Row color="brand">
        <Testimonial
          text="Prolinks saved me weeks of development time and thousands of dollars by eliminating the need to hire a developer to built the necessary infrastructure for my app. I had a beautiful product with billing capabilities in a matter of minutes!"
          author="Missak Boyajian, Prolinks Founder"
          image={ImgKyleGawley}
        />
      </Row> */}

      {/* <Row title="Kickstart Your SaaS App Today" align="center"> TODO
        <Button
          goto="/signup"
          text="Sign Up Now"
          className="inline-block"
          big
        />
      </Row> */}
    </Fragment>
  );
}
