import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Pricing } from "views/website/pricing";
import Grid from "@mui/material/Grid2";

export function PlansAndPayments() {
  const faqs = [
    {
      question: "Can I change my plan?",
      answer:
        "Yes, you can upgrade or downgrade your plan anytime from your account settings.",
    },
    {
      question: "Do you offer refunds for unused subscription periods?",
      answer:
        "Refunds are not provided for unused periods, but you can cancel anytime.",
    },
    {
      question: "Do I have full access with any plan?",
      answer:
        "Each plan offers specific features. Please refer to the plan details for more information.",
    },
    {
      question: "Can I try it for free?",
      answer:
        "Yes, you can use the platform for free with a 7-day trial to explore all features.",
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept credit cards, debit cards, and PayPal.",
    },
  ];

  return (
    <>
      <Pricing />
      <Box mt={4} mx={2}>
        <Grid container spacing={4}>
          {/* Left column with the title */}
          <Grid size={{ xs: 12, md: 4 }}>
            <Typography variant="h5" fontWeight="bold">
              Questions about plans
            </Typography>
          </Grid>
          {/* Right column with the accordions */}
          <Grid size={{ xs: 12, md: 8 }}>
            {faqs.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`faq-content-${index}`}
                  id={`faq-header-${index}`}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" fontWeight="medium">
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
