import React, { useContext } from "react";
import { Alert, AlertTitle, Button } from "@mui/material";
import { useNavigate } from "react-router";
import { AuthContext } from "components/lib";

export function AccountExpiredAlert() {
  const authContext = useContext(AuthContext);
  const expired = authContext?.user?.isExpired;
  const isFreeTier = authContext?.user?.plan === "free-tier";

  const navigate = useNavigate();

  const handleRenewClick = () => {
    navigate("/plans-and-payments");
  };

  // Render nothing if neither expired nor free-tier
  if (!expired && !isFreeTier) return null;

  return (
    <>
      {expired && (
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              variant="outlined"
              onClick={handleRenewClick}
            >
              Renew
            </Button>
          }
        >
          <AlertTitle>Account Expired</AlertTitle>
          Your account has expired. You need to renew your subscription.
        </Alert>
      )}

      {isFreeTier && (
        <Alert
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              variant="outlined"
              onClick={handleRenewClick}
            >
              Upgrade
            </Button>
          }
        >
          <AlertTitle>Free Tier</AlertTitle>
          You are currently using the free limited version of Prolinks.
        </Alert>
      )}
    </>
  );
}
