exports.getDefaultConfigurationValues = (resource) => {
  return {
    linkConfigurations: {
      scheduleActive: resource?.schedule_active ?? false,
      startDate: null,
      endDate: null,
      urlActive: resource?.url_active ?? false,
      customUrl: resource?.custom_url || "",
      scanLimitActive: resource?.scan_limit_active ?? false,
      scanLimit: resource?.scan_limit || "",
      folder_id: resource?.folder_id || null,
    },
  };
};

exports.mapTypesToDescription = (type) => {
  switch (type) {
    case "website":
      return "Website";
    case "pdf":
      return "Pdf";
    case "landing":
      return "Landing Page";
    default:
      break;
  }
};

exports.getPlans = () => {
  return [
    {
      id: "starter",
      title: "Starter",
      monthlyPrice: "$5.00",
      yearlyPrice: "$50.00",
      description: "For basic use",
      features: [
        "Up to 10 dynamic QR codes",
        "10,000 scans per month",
        "Basic analytics",
        "Basic design",
        "Premium Support",
      ],
    },
    {
      id: "standard",
      title: "Standard",
      monthlyPrice: "$15.00",
      yearlyPrice: "$150.00",
      description: "Best for small businesses",
      features: [
        "Up to 200 dynamic QR codes",
        "100,000 scans per month",
        "Advanced analytics",
        "Templates",
        "Bulk QR code creation",
        "Editable short URLs",
        "Premium Support",
      ],
      isPopular: true,
    },
    {
      id: "professional",
      title: "Professional",
      monthlyPrice: "$30.00",
      yearlyPrice: "$300.00",
      description: "Advanced tools for professionals",
      features: [
        "Up to 600 dynamic QR codes",
        "Unlimited scans",
        "Advanced analytics",
        "Templates",
        "Bulk QR code creation",
        "Editable short URLs",
        "Single custom domain",
        "Multi-users set up (max 10)",
        "Premium Support",
      ],
    },
    {
      id: "enterprise",
      title: "Enterprise",
      monthlyPrice: "$60.00",
      yearlyPrice: "$600.00",
      description: "Complete solution for enterprises",
      features: [
        "Up to 1500 dynamic QR codes",
        "Unlimited scans",
        "Advanced analytics",
        "Templates",
        "Bulk QR code creation",
        "Editable short URLs",
        "Create projects & tag QRs",
        "Multiple custom domains",
        "Multi-users set up",
        "API access",
        // "SSO (Single Sign-On) login",
        // "International phone support",
        // "Customer Success Manager",
        "Premium Support",
      ],
    },
  ];
};
