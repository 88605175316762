import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router";
import { Tabs, Tab, Box } from "@mui/material";
import { AuthContext } from "components/lib";

export function AccountNav() {
  const context = useContext(AuthContext);
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  if (value === "/account") {
    setValue("/account/profile");
  }

  const items = [
    { link: "/account/profile", label: "Profile" },
    { link: "/account/password", label: "Password" },
    { link: "/account/2fa", label: "2FA" },
    { link: "/account/plan", label: "Plans" },
    { link: "/account/payments", label: "Payments" },
    { link: "/account/billing", label: "Billing", permission: "owner" },
  ];

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="account navigation tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {items.map((item) => {
          if (item.permission && !context.permission[item.permission]) {
            return null;
          }

          return (
            <Tab
              key={item.label}
              label={item.label}
              value={item.link}
              component={NavLink}
              to={item.link}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
