import { Home } from "views/website/home";
import { Contact } from "views/website/contact";
import { Pricing } from "views/website/pricing";
import { Terms } from "views/website/terms";
import { Privacy } from "views/website/privacy";

const Routes = [
  {
    path: "/",
    view: Home,
    layout: "home",
    title: "Dynamic QR Code and link Generator | Discover All our QR Features ",
  },
  // {
  //   path: "/contact",
  //   view: Contact,
  //   layout: "home",
  //   title: "Contact us | Prolinks",
  // },
  {
    path: "/pricing",
    view: Pricing,
    layout: "home",
    title: "Started, Standard, Professional, Advanced Pricing Plans | Prolinks",
  },
  {
    path: "/privacy",
    view: Privacy,
    layout: "home",
    title: "Privacy Policy | Prolinks",
  },
  {
    path: "/terms",
    view: Terms,
    layout: "home",
    title: "Terms & Conditions | Prolinks",
  },
];

export default Routes;
