import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  AuthContext,
  BlankSlateMessage,
  useAPI,
  ViewContext,
} from "components/lib";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import {
  Box,
  CircularProgress,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Popover,
  ListItemIcon,
  ToggleButtonGroup,
  ToggleButton,
  CardActions,
  Chip,
  alertClasses,
  Stack,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  AddCircleOutline,
  AcUnitOutlined,
  EditOutlined,
  ClearRounded,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  SearchOutlined,
  DownloadOutlined,
  MoreVertOutlined,
  PrintOutlined,
  FavoriteOutlined,
  FileCopyOutlined,
  LanguageOutlined,
  ContentCopy,
  FavoriteBorder,
  AddOutlined,
} from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import Grid from "@mui/material/Grid2";

import { debounce } from "lodash";
import QRCodeStyling from "qr-code-styling";
import { useRef } from "react";
import { useContext } from "react";
import { mapTypesToDescription } from "constants/shared";
import { AccountExpiredAlert } from "components/expired/expired";

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Paused", value: "paused" },
  { label: "Pending", value: "pending" },
  { label: "Finished", value: "finished" },
  { label: "Deleted", value: "deleted" },
];

const qrCodeTypes = [
  { label: "Website", value: "website" },
  { label: "PDF", value: "pdf" },
  { label: "Image", value: "image" },
  { label: "Video", value: "video" },
];
const sortByOptions = [
  { label: "Most recent", value: "most_recent" },
  { label: "Name", value: "name" },
  { label: "Last modified", value: "last_modified" },
  { label: "Most visited", value: "most_visited" },
];
const quantityOptions = [5, 10, 20, 50, 100];

export function AccountLinks(props) {
  const [selectedButton, setSelectedButton] = useState("all");
  const [selectedCode, setSelectedCode] = useState(null);
  const [qrCodes, setQrCodes] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState([]);
  const [qrCodeType, setQrCodeType] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [sortBy, setSortBy] = useState("most_recent");
  const [quantity, setQuantity] = useState(10);
  const [page, setPage] = useState(1);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const expired = authContext?.user?.isExpired;
  const isFreeTier = authContext?.user?.plan === "free-tier";

  const { data: folders, error } = useAPI("/api/folders");

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMorePopover = (event, code) => {
    setAnchorEl(event.currentTarget);
    setSelectedCode({ ...code });
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const qrRefs = useRef([]);

  useEffect(() => {
    qrRefs.current = qrRefs.current.slice(0, qrCodes.length);

    qrCodes.forEach((item, index) => {
      // Clear any existing QR code before appending a new one
      const container = qrRefs.current[index];
      if (container) {
        container.innerHTML = ""; // Clear previous QR code

        const qrCode = new QRCodeStyling({
          ...item.qr_options,
          width: 95,
          height: 95,
          margin: 4,
        });

        qrCode.append(container);
      }
    });
  }, [qrCodes]);

  useEffect(() => {
    if (initialFetchDone) {
      updateParamsAndFetch();
    }
  }, [searchText, status, qrCodeType, sortBy, quantity, page, selectedFolder]);

  useEffect(() => {
    fetchQrCodes();
  }, []);

  useEffect(() => {
    fetchOnly();
  }, [selectedButton]);

  const fetchQrCodes = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axios.get("/api/links", { params });
      setQrCodes(response.data.data);
      setMeta(response.data.meta);
      setInitialFetchDone(true);
    } catch (error) {
      console.error("Error fetching QR codes:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = useCallback(
    debounce((params) => {
      fetchQrCodes(params);
    }, 600),
    []
  );

  const cleanParams = (params) => {
    return Object.fromEntries(
      Object.entries(params).filter(
        ([key, value]) => value !== null && value !== undefined && value !== ""
      )
    );
  };

  const updateParamsAndFetch = () => {
    const params = {
      search: searchText,
      status: status.join(","),
      qr_type: qrCodeType.join(","),
      sort_by: sortBy,
      size: quantity,
      page,
      folder: selectedFolder,
    };
    debouncedFetch(cleanParams(params));
  };

  const fetchOnly = () => {
    const params = {
      search: searchText,
      status: status.join(","),
      qr_type: qrCodeType.join(","),
      folder: selectedFolder,
      sort_by: sortBy,
      size: quantity,
      page,
      only_favorites: selectedButton === "favorites" ? true : null,
    };
    fetchQrCodes(cleanParams(params));
  };

  const downloadQRCode = async (code) => {
    const qrToDownload = new QRCodeStyling(code.qr_options);

    await qrToDownload.download({
      extension: "png",
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleQrCodeTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setQrCodeType(typeof value === "string" ? value.split(",") : value);
  };

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleFolderChange = (event) => {
    setSelectedFolder(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleCleanFilter = () => {
    setSortBy("most_recent");
    setQuantity(10);
    setPage(1);
    setQrCodeType([]);
    setStatus([]);
    setSelectedFolder(null);
    setSearchText(null);
  };

  const handleEdit = () => {
    navigate(`/links/${selectedCode.dynamic_link_id}/edit`);
  };

  const handlePrint = () => {};

  const handleCopyClick = (code) => {
    navigator.clipboard.writeText(code.full_dynamic_url);
    context.notification.show(
      `${code.full_dynamic_url} copied to clipboard`,
      "success",
      true
    );
  };

  const handleLinkClick = (link) => {
    window.open(link, "_blank");
  };

  const handleDuplicate = async () => {
    try {
      await axios.post(`/api/links/${selectedCode.id}/duplicate`);
      fetchOnly();
      handleMoreOptionsClose();
      context.notification.show("QR Code Duplicated", "success");
    } catch (err) {
      context.notification.show("Something went wrong", "error");
      console.log(err);
    }
  };
  const handleFavorite = async () => {
    try {
      await axios.post(`/api/links/${selectedCode.id}/favorite`);
      handleMoreOptionsClose();
      fetchOnly();
      context.notification.show("QR Added to Favourites", "success");
    } catch (err) {
      context.notification.show("Something went wrong", "error");
      console.log(err);
    }
  };

  const handleDeleteFavorite = async () => {
    try {
      await axios.delete(`/api/links/${selectedCode.id}/favorite`);
      handleMoreOptionsClose();
      fetchOnly();
      context.notification.show("QR Removed from Favourites", "success");
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabSelection = (event, newSelection) => {
    if (newSelection !== null) {
      setSelectedButton(newSelection);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "success";
      case "paused":
        return "secondary";
      case "inactive":
        return "default";
      // Add more cases as needed
      default:
        return "default";
    }
  };
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMoreOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem onClick={handleDuplicate}>
            <ListItemIcon>
              <FileCopyOutlined fontSize="small" />
            </ListItemIcon>
            <Typography>Duplicate</Typography>
          </MenuItem>
          <MenuItem onClick={handlePrint}>
            <ListItemIcon>
              <PrintOutlined fontSize="small" />
            </ListItemIcon>
            <Typography>Print</Typography>
          </MenuItem>
          {selectedCode && selectedCode.is_favorite && (
            <MenuItem onClick={handleDeleteFavorite}>
              <ListItemIcon>
                <StarIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Remove from favorites</Typography>
            </MenuItem>
          )}
          {selectedCode && !selectedCode.is_favorite && (
            <MenuItem onClick={handleFavorite}>
              <ListItemIcon>
                <FavoriteBorder fontSize="small" />
              </ListItemIcon>
              <Typography>Add to favorites</Typography>
            </MenuItem>
          )}
        </Box>
      </Popover>

      {/* <AccountExpiredAlert /> */}

      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="outlined"
          color="primary"
          disabled={expired}
          startIcon={<AddOutlined />}
          onClick={() => navigate("/links/create")}
        >
          Create QR Code
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <ToggleButtonGroup
          value={selectedButton}
          exclusive
          disabled={expired}
          color="primary"
          sx={{ backgroundColor: "white" }}
          onChange={handleTabSelection}
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value="favorites">Favorites</ToggleButton>
        </ToggleButtonGroup>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl
            variant="outlined"
            disabled={expired}
            sx={{ minWidth: 140 }}
            size="small"
          >
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={handleSortByChange}
              label="Sort By"
              sx={{ backgroundColor: "white" }}
            >
              {sortByOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            disabled={expired}
            variant="outlined"
            sx={{ minWidth: 100 }}
            size="small"
          >
            <InputLabel>Quantity</InputLabel>
            <Select
              value={quantity}
              onChange={handleQuantityChange}
              label="Quantity"
              sx={{ backgroundColor: "white" }}
            >
              {quantityOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" alignItems="center">
            <Typography>
              Page {page} of {meta.totalPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(1)}
              disabled={page === 1}
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              <NavigateBefore />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(page + 1)}
              disabled={page === meta.totalPages}
            >
              <NavigateNext />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange(meta.totalPages)}
              disabled={page === meta.totalPages}
            >
              <LastPage />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          label="Search"
          disabled={expired}
          variant="outlined"
          size="small"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchText("")}>
                  <ClearRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl
          disabled={expired}
          variant="outlined"
          sx={{ minWidth: 200 }}
          size="small"
        >
          <InputLabel>Status</InputLabel>
          <Select
            multiple
            value={status}
            onChange={handleStatusChange}
            size="small"
            sx={{ backgroundColor: "white" }}
            input={<OutlinedInput label="Status" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={status.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          disabled={expired}
          variant="outlined"
          sx={{ minWidth: 200 }}
          size="small"
        >
          <InputLabel>QR Code Type</InputLabel>
          <Select
            multiple
            value={qrCodeType}
            label={"QR Code Type"}
            onChange={handleQrCodeTypeChange}
            size="small"
            sx={{ backgroundColor: "white" }}
            input={<OutlinedInput label="QR Code Type" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {qrCodeTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={qrCodeType.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          disabled={expired}
          variant="outlined"
          sx={{ minWidth: 200 }}
          size="small"
        >
          <InputLabel>Folder</InputLabel>
          <Select
            value={selectedFolder} // Ensure this holds a single value
            onChange={handleFolderChange}
            sx={{ backgroundColor: "white" }}
            input={<OutlinedInput label="Folder" />}
          >
            <MenuItem key={"All"} value={""}>
              All
            </MenuItem>
            {folders &&
              folders.map((folder) => (
                <MenuItem key={folder.id} value={folder.id}>
                  {folder.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Typography
          variant="body2"
          color={"primary"}
          sx={{ cursor: "pointer", marginLeft: "auto" }}
          onClick={handleCleanFilter}
        >
          Clean filters
        </Typography>
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : qrCodes.length > 0 ? (
        <Box>
          <Grid container>
            {qrCodes.map((code, index) => (
              <Box key={index} marginTop={2} width={"100%"}>
                <Card>
                  <CardContent style={{ padding: "8px" }}>
                    <Grid container alignItems="center">
                      <Grid size={{ lg: 1, md: 4, sm: 12 }}>
                        <Box
                          display={"inline-block"}
                          key={code.id}
                          width={95}
                          height={95}
                          ref={(el) => (qrRefs.current[index] = el)}
                        />
                      </Grid>
                      <Grid size={{ lg: 2, md: 4, sm: 12 }}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body1" color="secondary">
                            {mapTypesToDescription(code.type)}
                          </Typography>
                          {code.is_favorite && (
                            <StarIcon
                              color="secondary"
                              style={{ marginLeft: 4 }}
                            />
                          )}
                        </Box>
                        <Typography variant="body2">{code.name}</Typography>
                      </Grid>

                      <Grid size={{ lg: 3, md: 4, sm: 12 }}>
                        <Box display={"flex"} alignItems={"center"}>
                          <LanguageOutlined fontSize={"small"} />
                          <Typography
                            variant={"body2"}
                            marginLeft={"4px"}
                            onClick={() =>
                              handleLinkClick(code.full_dynamic_url)
                            }
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {code.full_dynamic_url}
                          </Typography>
                          <IconButton
                            aria-label="copy"
                            onClick={() => handleCopyClick(code)}
                            size="small"
                            sx={{ marginLeft: "4px" }}
                          >
                            <ContentCopy sx={{ fontSize: "16px" }} />
                          </IconButton>
                        </Box>
                        <Box display="flex" alignItems="center" marginTop="8px">
                          {code.type === "website" && (
                            <>
                              <EditOutlined fontSize="small" />
                              <Typography
                                variant="body2"
                                marginLeft="4px"
                                onClick={() =>
                                  handleLinkClick(code.meta_data?.url)
                                }
                                sx={{
                                  cursor: "pointer",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "200px",
                                }}
                                title={code.meta_data?.url}
                              >
                                {code.meta_data?.url}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Grid>
                      <Grid size={{ lg: 2, md: 4, sm: 12 }}>
                        <Box display={"flex"} alignContent={"center"}>
                          <AcUnitOutlined fontSize={"small"}></AcUnitOutlined>
                          <Typography variant={"body2"} marginLeft={"4px"}>
                            Created: {code.created_at}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          alignContent={"center"}
                          marginTop={"8px"}
                        >
                          <EditOutlined fontSize={"small"}></EditOutlined>
                          <Typography variant={"body2"} marginLeft={"4px"}>
                            Modified: {code.updated_at}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid size={{ lg: 1, md: 4, sm: 12 }}>
                        <Box textAlign={"center"}>
                          <Typography variant="h5" color={"secondary"}>
                            {code.total_scans}
                          </Typography>
                          <Typography variant="p">Scans</Typography>
                        </Box>
                      </Grid>
                      <Grid size={{ lg: 3, md: 4, sm: 12 }}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"center"}
                        >
                          <Chip
                            label={code.status}
                            color={getStatusColor(code.status)}
                            sx={{ marginRight: 1 }}
                            size="small"
                          />
                          <Button
                            variant={"outlined"}
                            disabled={expired}
                            onClick={() =>
                              navigate(`/links/${code.dynamic_link_id}`)
                            }
                            sx={{ marginRight: 1 }}
                          >
                            Details
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={expired}
                            sx={{ marginRight: 1 }}
                            onClick={() => downloadQRCode(code)}
                          >
                            <DownloadOutlined />
                          </Button>
                          <IconButton
                            disabled={expired}
                            onClick={(event) => handleMorePopover(event, code)}
                          >
                            <MoreVertOutlined />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Grid>
        </Box>
      ) : (
        <>
          {/* <h6>{loading.toString()}</h6> */}
          {selectedButton === "all" ? (
            <BlankSlateMessage
              title="No QR codes found"
              action={() => navigate("/links/create")}
              buttonText="Create QR Code"
            />
          ) : selectedButton === "favorites" ? (
            <BlankSlateMessage title="No favorites selected" />
          ) : null}
        </>
      )}
    </>
  );
}
